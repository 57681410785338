import request from "@/utils/request";

export const getList = function(params) {
    params = { isDeleted: 0, ...params };
    return request({
        url: "/visual/category/list",
        method: "get",
        params: params,
    });
};

export const getObj = (id) =>
    request({
        url: "/visual/category/detail",
        method: "get",
        params: {
            id,
        },
    });

export const addObj = (data) =>
    request({
        url: "/visual/category/save",
        method: "post",
        data: data,
    });
export const updateObj = (data) =>
    request({
        url: "/visual/category/update",
        method: "post",
        data: data,
    });

export const delObj = (id) =>
    request({
        url: "/visual/category/remove",
        method: "post",
        params: {
            ids: id,
        },
    });
