import request from "@/utils/request";

export const getList = (params) => {
    params = { isDeleted: 0, ...params };
    return request({
        url: "/visual/list",
        method: "get",
        params: params,
    });
};

export const copyObj = (id) =>
    request({
        url: "/visual/copy",
        method: "post",
        params: {
            id: id,
        },
    });

export const getObj = (id) =>
    request({
        url: "/visual/detail",
        method: "get",
        params: {
            id,
        },
    });
export const uploadImg = (file, filename) => {
    var formdata = new FormData();
    formdata.append("picturefile", file);
    formdata.append("imgPath", "visual");
    formdata.append("needWatermark", false);
    formdata.append("objKey", filename);

    return request({
        url: "/common/picture/cos",
        method: "post",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const addObj = (data) =>
    request({
        url: "/visual/save",
        method: "post",
        data: {
            visual: {
                password: data.password,
                category: data.category,
                status: data.status,
                title: data.title,
                isDeleted: data.isDeleted,
            },
            config: {
                detail: JSON.stringify({
                    name: data.title,
                    width: data.width,
                    height: data.height,
                    scale: 1,
                    backgroundImage: "/img/bg/bg1.png",
                    url: "",
                    mark: {},
                    gradeShow: false,
                    gradeLen: 30,
                }),
                component: "[]",
            },
        },
    });

export const updateComponent = (data) =>
    request({
        url: "/visual/update",
        method: "post",
        data: data,
    });

export const updateObj = (data) =>
    request({
        url: "/visual/update",
        method: "post",
        data: {
            visual: {
                id: data.id,
                password: data.password,
                category: data.category,
                status: data.status,
                title: data.title,
            },
        },
    });

export const delObj = (id) =>
    request({
        url: "/visual/remove",
        method: "post",
        params: {
            ids: id,
        },
    });
