<template>
    <div class="index">
        <div class="header">
            <img src="/img/header.jpg" alt width="100%" height="210px" />
            <el-row type="flex" class="row-bg title" justify="space-between">
                <el-col :span="12">
                    <div class="">
                        <p>
                            督贝督导
                            <br />
                            <small>可视化数据大屏</small>
                        </p>
                    </div>
                </el-col>
                <el-col :span="12" class="right-el-col">
                    <el-dropdown @command="logout">
                        <div class="user-info">
                            欢迎您,{{ this.$store.state.user.nickName }}
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1"
                                >退出</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
            <el-menu
                class="nav"
                mode="horizontal"
                :default-active="activeName"
                background-color="rgba(0,0,0,0)"
                text-color="#fff"
                @select="handleSelect"
                active-text-color="#00baff"
            >
                <el-menu-item index="1"
                    ><i class="el-icon-menu"></i>
                    大屏管理
                </el-menu-item>
                <el-menu-item index="2">
                    <i class="el-icon-location-information"></i>
                    地图管理
                </el-menu-item>
                <el-menu-item index="3">
                    <i class="el-icon-folder-opened"></i>
                    分类管理
                </el-menu-item>
            </el-menu>
        </div>
        <el-scrollbar class="main">
            <list v-if="activeName == 1"></list>
            <maps v-if="activeName == 2"></maps>
            <category v-if="activeName == 3"></category>
        </el-scrollbar>
    </div>
</template>
<script>
import list from "./list/index";
import maps from "./list/map";
import category from "./list/category";
export default {
    name: "index",
    components: {
        list,
        maps,
        category,
    },
    data() {
        return {
            activeName: "1",
        };
    },
    async created() {
        await this.$store.dispatch("GetInfo");
    },
    methods: {
        handleSelect(key) {
            this.activeName = key;
        },
        // 退出登录
        async logout(command) {
            this.$store.dispatch("FedLogOut").then((res) => {
                this.$router.push("/login");
            });
        },
    },
};
</script>
<style lang="scss">
.index {
    height: 100%;
    .header {
        position: relative;
        padding: 0;
        height: auto !important;
        .nav {
            margin: 0 20px;
            width: 100%;
            position: absolute;
            bottom: 10px;
            border: none;
            .el-menu-item {
                background-color: rgba(0, 0, 0, 0) !important;
            }
        }
        .title {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0;
            padding: 30px 30px 0 30px;
            font-size: 34px;
            color: rgb(0, 186, 255);
            font-weight: 500;
            .right-el-col {
                text-align: right;
                .user-info {
                    font-size: 34px;
                    color: rgb(0, 186, 255);
                    font-weight: 500;
                }
            }
        }
        .title small {
            font-size: 18px;
        }
    }
    .main {
        width: 100%;
        height: calc(100% - 220px);
    }
}
.el-dropdown-menu {
    margin: 0 !important;
}
</style>
