import request from "@/utils/request";

export const getList = (params) =>
    request({
        url: "/visual/map/list",
        method: "get",
        params: params,
    });

export const getObj = (id) =>
    request({
        url: "/visual/map/detail",
        method: "get",
        params: {
            id,
        },
    });

export const addObj = (data) =>
    request({
        url: "/visual/map/save",
        method: "post",
        data: data,
    });
export const updateObj = (data) =>
    request({
        url: "/visual/map/update",
        method: "post",
        data: data,
    });

export const delObj = (id) =>
    request({
        url: "/visual/map/remove",
        method: "post",
        params: {
            ids: id,
        },
    });
